import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { Outlet } from "react-router-dom";
/**
 * Anywhere Teacher modules
 */
import Footer from "../containers/Footer";
import MainDrawer from "../components/MainDrawer";
import React from "react";

const Root = () => {
	return (
		<React.Fragment>
			<Box>
				<div id="root-module">
					<Outlet />
				</div>
			</Box>
			<MainDrawer />
			<Container>
				<Footer />
			</Container>
		</React.Fragment>
	);
}

export default Root;
