import AbstractCommand from "./AbstractCommand";

class DogCommand extends AbstractCommand {

    execute () {
		if (this.params[1] === "?") return this.help();

		this.output.setTitle("Dog Command");
		this.output.addField("Field Heading", "Lorem ipsum dolor sit amet, consectetur adipiscing elite");

        return this.output.render();
    }

	help () {
		this.output.setTitle("Test Command");
		this.output.addField("", 
			`used to dog the command factory and can be used as a template for all new commands.
			
			No parameters supported.`
		);
        return this.output.render();
	}

	about () {
		return {
			name: "Dog",
			description: "Make a dog"
		}
	}
}

export default DogCommand;
