export type TableHeader = Array<string>;
export type TableColumns = Array<string>;
export type TableRows = Array<TableColumns>;
 
export interface ITable {
	header: TableHeader,
	rows: TableRows
}

export type List = Array<string>;

class MDOutput {
	private output: string;

	constructor () {
		this.output = "";
	}

	private addOutput = (content: string): void => {
		this.output += content;
	}

	private closeElement = () => {
		this.addOutput(`  \n`);
	}

	private whiteSpace = (count: number): string => {
		let whiteSpace = "";
		for (let i = 0; i < count; i++) {
			whiteSpace += "&nbsp;";
		}

		return whiteSpace;
	}

	public setTitle = (content: string): void => {
		this.addOutput(`# ${content}  \n`);
	}

	public addTable = (table: ITable, padding: number): void => {
		let header = "| ";
		let divider = "| ";
		table.header.forEach((item: string) => {
			header += `${this.whiteSpace(padding)}${item}${this.whiteSpace(padding)} | `;
			divider += " :---: | ";
		});

		let rows = "| "
		table.rows.forEach((row: TableColumns) => {
			row.forEach((item: string) => {
				rows += `${item} | `;
			})
			rows += "  \n|";
		})
		rows = rows.slice(0, rows.length - 1);

		this.addOutput(`${header}  \n${divider}  \n${rows}  \n`);
	}

	public addField = (heading: string, body: string): void => {
		if (heading.length > 0) {
			this.addOutput(`#### ${heading}\n`);
		}
		if (body.length > 0) {
			this.addOutput(`${body}  \n`);
		}
		this.closeElement();
	}

	public addHR = (): void => {
		this.addOutput(`---  \n`);
	}

	public addOrderedList = (list: List): void => {
		list.forEach((item: string, index: number) => {
			this.addOutput(`${index + 1}. ${item}  \n`);
		});
		this.closeElement();
	}

	public addUnorderedList = (list: List): void => {
		list.forEach((item: string) => {
			this.addOutput(`* ${item}  \n`);
		});
		this.closeElement();
	}

	public addImage = (imageUrl: string) => {
		this.addOutput(`![Image](${imageUrl})  \n`);
		this.closeElement();
	}

	public addLink = (link: string, linkUrl: string) => {
		this.addOutput(`![${link}](${linkUrl})  \n`);
		this.closeElement();
	}

	public addCodeBlock = (code: string) => {
		this.addOutput(`\`\`\`${code}\`\`\`  \n`);
		this.closeElement();
	}

	public addBlockQuote = (quote: string) => {
		this.addOutput(`> ${quote}  \n`)
		this.closeElement();
	}

	public render = () => {
		// trim the end of the output- remove double space and return
		return this.output.slice(0, this.output.length - 3);
	}
}

export default MDOutput;
