import AbstractCommand from "./AbstractCommand";

class UnknownCommand extends AbstractCommand {    
    execute = () => {
		if (this.params[1] === "?") return this.help();

		this.output.setTitle("Seriously? Not like that!");
		this.output.addField("Error", `**${this.params[0]}** is not a command!`);

        return this.output.render();
    }

	help () {
		this.output.setTitle("Unknown Command");
		this.output.addField("", `Try using **list all** for a list of commands`);
        return this.output.render();
	}

	about () {
		return {
			name: "Unknown",
			description: "Really? It's unknown"
		}
	}
}

export default UnknownCommand;
