import AbstractCommand, { IAbout } from "./AbstractCommand";

class TestCommand extends AbstractCommand {

    execute() {
		if (this.params[1] === "?") return this.help();

		this.output.setTitle("Test Command");
		this.output.addField("Field Heading", "Lorem ipsum dolor sit amet, consectetur adipiscing elite");

        return this.output.render();
    }

	help () {
		this.output.setTitle("Test Command");
		this.output.addField("", 
			`used to test the command factory and can be used as a template for all new commands.
			
			No parameters supported.`
		);
        return this.output.render();
	}

	about (): IAbout {
		return {
			name: "Test",
			description: "Test some stuff"
		}
	}
}

export default TestCommand;
