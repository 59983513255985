import Phaser from "phaser";
import PhaserFWScene from "./PhaserFWScene";

class PhaserFWConfig {
    private _config: Phaser.Types.Core.GameConfig;

    constructor () {
        this._config = {
            type: Phaser.AUTO,
            title: "AT 2.0.0.dev-1",
            width: 1280,
            height: 720,
            parent: "at2",
            backgroundColor: "#000000",
            scene: [PhaserFWScene]
        }
    }

    public get config(): Phaser.Types.Core.GameConfig {
        return this._config;
    }
}

export default PhaserFWConfig;