import { createSlice, PayloadAction  } from "@reduxjs/toolkit";
import PhaserFWConfig from "../classes/phaser/PhaserFWConfig";

interface IDrawState {
	isVisible: boolean,
	phaser: any
}

const initialState: IDrawState = {
	isVisible: false,
	phaser: undefined
}

const phaserConfig = new PhaserFWConfig();

const mainDrawSlice = createSlice({
	name: "draw",
	initialState,
	reducers: {
		setCanvas: (state) => {
			state.isVisible = true;
		},
		clearCanvas: (state) => {
			state.isVisible = false;
			const element = document.getElementById(phaserConfig.config.parent as string);
			if (element && element.innerHTML.length > 0) {
				element.innerHTML = "";
			}
		},
		setPhaser: (state, action: PayloadAction<any>) => {
			state.phaser = action.payload;
		}
	}
});

export const { setCanvas, clearCanvas, setPhaser } = mainDrawSlice.actions;

export default mainDrawSlice.reducer;
