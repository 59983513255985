import Phaser from "phaser";

class PhaserFWGame extends Phaser.Game {
	public version: string;

	constructor(config: Phaser.Types.Core.GameConfig) {
		super(config);
		this.version = "2.0.0-dev.2";
	}
}

export default PhaserFWGame;
