import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { logger } from "redux-logger";
import mainDrawerSlice from "../slices/mainDrawerSlice";
import drawSlice from "../slices/drawSlice";

const stage: string = typeof process.env.REACT_APP_STAGE !== "undefined" ? process.env.REACT_APP_STAGE : "local";
/**
 * #### Set Non-production Middleware 
 *   
 * Include middleware in the build for every stage excluding "prod".  
 *   
 * We can't use **process.env.NODE_ENV** because it is set to "production" if not local development.
 * We could override NODE_ENV but it appears any number of npm packages do special things when
 * NODE_ENV = "production" and we want that to occur when stages are standing alone on AWS.  
 *   
 * NOTE: make sure redux-logger **logger** is the last middleware added
 */
let optionalMiddleware: any = [];
if (stage !== "prod") {
	optionalMiddleware.push(logger);
}
/**
 * Initialize the Redux Store 
 */
export const store = configureStore({
	reducer: {
		drawer: mainDrawerSlice,
		draw: drawSlice
	},
	middleware: (getDefaultMiddleware) => 
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActionPaths: ["payload.phaser"],
				ignoredPaths: ["draw.phaser"],
			}
		}).concat(optionalMiddleware)
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	RootState,
	unknown,
	Action<string>
>;
