import React from "react";
import * as ReactDOM from "react-dom/client";
import { store } from "./app/store";
import { Provider as ReduxProvider } from "react-redux";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import { createBrowserRouter, RouterProvider, createRoutesFromElements, Route } from "react-router-dom";

import CommandRoute from "./routes/CommandRoute";
import PhaserRoute from "./routes/PhaserRoute";
import Root from "./routes/Root";
import ErrorPage from "./containers/Error";
/** 
 * Sentry Integration (see https://sentry.io)
 */
 import { initSentry } from './lib/errorLib';
 import ErrorBoundary from "./components/ErrorBoundary";

 initSentry();

 /** 
 * Routing and Redux integration
 * https://www.npmjs.com/package/@lagunovsky/redux-react-router
 * redux-react-router may not be compatible with react-router v6.4 as it looks like CreateBrowserRouter 
 * uses the DOM History API as opposed to the history package from Remix themselves.  Can't track it down 
 * exactly but see https://reactrouter.com/en/6.4.0-pre.14/routers/history-router for indications and 
 * the history-router is gone from 6.4.1
 * 
 * https://github.com/salvoravida/redux-first-history also may provide some light on how to manage state and browsing history
 * 
 * https://blog.logrocket.com/react-router-with-redux-navigation-state/
 */
const router = createBrowserRouter(
	createRoutesFromElements(
		<Route path="/" element={<Root />} errorElement={<ErrorPage />} >
			<Route path="cli" element={<CommandRoute />} />
			<Route path="phaser" element={<PhaserRoute />} />
		</Route>
	)
);

const root = ReactDOM.createRoot(document.getElementById("root")!);
//
// Remove React.StrictMode temporarily for Phaser 3 Research (ATA-250), however, we will want to make sure that 
// Phaser can properly load and unload and restart later if we decide to maintain Phaser 3 inside of AT 2
//
// <React.StrictMode></React.StrictMode> wrapped around entire render tree
//
root.render(
	<ErrorBoundary>
		<ReduxProvider store={store}>
			<ThemeProvider theme={theme}>
				<CssBaseline enableColorScheme />
				<RouterProvider
					router={router}
					// fallbackElement={<LoadingIndicator />}
				/>
			</ThemeProvider>
		</ReduxProvider>
	</ErrorBoundary>
);
