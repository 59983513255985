import TestCommand from "./TestCommand";
import DogCommand from "./DogCommand";
import UnknownCommand from "./UnknownCommand";
import { CommandParameters } from "./AbstractCommand";
/**
 * #### Command Factory
 * 
 * Standard factory pattern implementation to support simple command processing.  
 *   
 * **CommandParameters**  
 * are structured as an array of strings where, at least, the first parameter [index 0] describes the command
 * that is going to be processed.  Multiple word commands can be implemented by adding additional hierarchy of
 * switches within a commands case structure.
 *
 * @class CommandFactory
 */
class CommandFactory {
	/**
	 * #### Get Command from Factory
	 *
	 * @param CommandParameters 						base 0 array of a command string
	 * @returns 										instantianted command object
	 */
	public getCommand = (commandParameters: CommandParameters): any => {
		if (commandParameters.length <= 0) return new UnknownCommand(commandParameters);

        switch (commandParameters[0]) {
            case "test":
			case "t":
                return new TestCommand(commandParameters);

			case "dog":
				return new DogCommand(commandParameters);

            default:
                return new UnknownCommand(commandParameters);
        }
    }

	// public commands = (): Array<string> => {
	// 	return this.commands;
	// }
}

export default CommandFactory;
